import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import * as yup from 'yup';

import { StepForm } from '@components';
import {
  AVAILABILITY,
  CONTRACT,
  FORM_TYPES,
  LINKEDIN_REGEX,
  MODALITY,
  PHONE_REGEX,
  PROFESSIONAL_LEVELS,
  STATE,
  WORKING_DAY,
} from '../../../../utils/consts';
import actionHelper from '../../../../utils/action-helper';
import PROFESSIONAL_ACTION_TYPES from '../../../../store/actions/PROFESSIONAL_ACTION_TYPES';
import useReduxData from '../../../../hooks/useReduxData';
import * as styles from './style.module.scss';

const Form = ({ intl: { formatMessage } }) => {
  const actualYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const theme = useTheme();
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const limitTags = matchesSmDown
    ? 1
    : matchesMdDown
      ? 2
      : matchesLgDown
        ? 4
        : 6;

  const {
    countries,
    provinces,
    cities,
    languages,
    educations,
    technologies,
    methodologies,
    profiles,
    certifications,
  } = useReduxData();
  const { professionalAdd } = useSelector(
    (stateRdx) => ({
      professionalAdd: stateRdx.professionalAdd,
    }),
    shallowEqual
  );

  const steps = [
    formatMessage({ id: 'professional' }),
    formatMessage({ id: 'profile' }),
    formatMessage({ id: 'extras' }),
  ];

  const fields = [
    //Step 1
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'name',
      label: formatMessage({ id: 'fullName' }),
      step: 0,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'fullName' }),
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'lastName',
      label: formatMessage({ id: 'lastName' }),
      step: 0,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'lastName' }),
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'email',
      label: formatMessage({ id: 'email' }),
      step: 0,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'email' }),
        type: 'email',
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'phone',
      label: formatMessage({ id: 'phoneNumber' }),
      step: 0,
      defaultValue: '',
      ComponentProps: {
        placeholder: formatMessage({ id: 'phoneNumber' }),
      },
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'linkedin',
      label: formatMessage({ id: 'linkedin' }),
      step: 0,
      defaultValue: '',
      fullWidth: true,
      ComponentProps: {
        placeholder: formatMessage({ id: 'linkedin' }),
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'country',
      label: formatMessage({ id: 'country' }),
      step: 0,
      defaultValue: null,
      options: countries.data,
      loading: countries.loading,
      resetFieldOnChange: ['province', 'city'],
      onChange: (value) => {
        provinces.getData(value.code);
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'province',
      label: formatMessage({ id: 'province' }),
      step: 0,
      defaultValue: null,
      options: provinces.data,
      loading: provinces.loading,
      resetFieldOnChange: 'city',
      onChange: (value) => {
        cities.getData(value.id);
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'city',
      label: formatMessage({ id: 'city' }),
      step: 0,
      defaultValue: null,
      options: cities.data,
      loading: cities.loading,
    },

    //Step 2
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'profiles',
      label: formatMessage({ id: 'profiles' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: profiles.data,
      loading: profiles.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'mainTechnologies',
      label: formatMessage({ id: 'mainTechnologies' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: technologies.data,
      loading: technologies.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'technologies',
      label: formatMessage({ id: 'otherTechnologies' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: technologies.data,
      loading: technologies.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'methodologies',
      label: formatMessage({ id: 'mainMethodologies' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: methodologies.data,
      loading: methodologies.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'certifications',
      label: formatMessage({ id: 'certifications' }),
      step: 1,
      defaultValue: [],
      fullWidth: true,
      options: certifications.data,
      loading: certifications.loading,
      ComponentProps: {
        multiple: true,
        disableCloseOnSelect: true,
        limitTags: limitTags,
      },
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'educations',
      label: formatMessage({ id: 'education' }),
      step: 1,
      defaultValue: null,
      fullWidth: true,
      options: educations.data,
      loading: educations.loading,
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'languages0',
      label: formatMessage({ id: 'mainLanguage' }),
      step: 1,
      defaultValue: null,
      options: languages.data,
      loading: languages.loading,
    },
    {
      type: FORM_TYPES.AUTOCOMPLETE,
      name: 'languages1',
      label: formatMessage({ id: 'secondaryLanguage' }),
      step: 1,
      defaultValue: null,
      options: languages.data,
      loading: languages.loading,
    },

    //Step 3
    {
      type: FORM_TYPES.SELECT,
      name: 'professionalLevel',
      label: formatMessage({ id: 'professionalLevel' }),
      step: 2,
      defaultValue: '',
      options: PROFESSIONAL_LEVELS,
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'experienceSince',
      label: formatMessage({ id: 'experienceSince' }),
      step: 2,
      defaultValue: actualYear - 1,
      ComponentProps: {
        placeholder: formatMessage({ id: 'experienceSince' }),
        type: 'number',
      },
    },
    {
      type: FORM_TYPES.SELECT,
      name: 'workingDay',
      label: formatMessage({ id: 'workingDay' }),
      step: 2,
      defaultValue: [],
      options: WORKING_DAY,
      ComponentProps: {
        SelectProps: { multiple: true },
      },
    },
    {
      type: FORM_TYPES.SELECT,
      name: 'mode',
      label: formatMessage({ id: 'modality' }),
      step: 2,
      defaultValue: [],
      options: MODALITY,
      ComponentProps: {
        SelectProps: { multiple: true },
      },
    },
    {
      type: FORM_TYPES.SELECT,
      name: 'typeContract',
      label: formatMessage({ id: 'contractType' }),
      step: 2,
      defaultValue: [],
      options: CONTRACT,
      ComponentProps: {
        SelectProps: { multiple: true },
      },
    },
    {
      type: FORM_TYPES.SELECT,
      name: 'availability',
      label: formatMessage({ id: 'availability' }),
      step: 2,
      defaultValue: '',
      options: AVAILABILITY,
    },
    {
      type: FORM_TYPES.TEXTFIELD,
      name: 'salary',
      label: formatMessage({ id: 'salary' }),
      step: 2,
      defaultValue: 0,
      ComponentProps: {
        placeholder: formatMessage({ id: 'salary' }),
        type: 'number',
      },
    },
    {
      type: FORM_TYPES.RANGE,
      name: ['payFrom', 'payTo'],
      label: [formatMessage({ id: 'payFrom' }), formatMessage({ id: 'payTo' })],
      step: 2,
      defaultValue: ['', ''],
    },
    {
      type: FORM_TYPES.TEXTAREA,
      name: 'comments',
      label: formatMessage({ id: 'comments' }),
      step: 2,
      defaultValue: '',
      fullWidth: true,
      ComponentProps: {
        placeholder: formatMessage({ id: 'comments' }),
        rows: 6,
      },
    },
  ];

  const schema = yup.object().shape({
    name: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .required(formatMessage({ id: 'stringValidation' })),
    lastName: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .required(formatMessage({ id: 'stringValidation' })),
    email: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .required(formatMessage({ id: 'stringValidation' }))
      .email(formatMessage({ id: 'emailValidation' })),
    phone: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .required(formatMessage({ id: 'stringValidation' }))
      .matches(PHONE_REGEX, formatMessage({ id: 'phoneValidation' })),
    linkedin: yup
      .string(formatMessage({ id: 'stringValidation' }))
      .matches(LINKEDIN_REGEX, {
        message: formatMessage({ id: 'linkendinValidation' }),
        excludeEmptyString: true,
      }),
    experienceSince: yup
      .number(formatMessage({ id: 'numberValidation' }))
      .typeError(formatMessage({ id: 'numberValidation' }))
      .min(0, formatMessage({ id: 'minValidation' }, { min: 0 }))
      .max(
        actualYear,
        formatMessage({ id: 'maxValidation' }, { max: actualYear })
      ),
    salary: yup
      .number(formatMessage({ id: 'numberValidation' }))
      .typeError(formatMessage({ id: 'numberValidation' }))
      .min(0, formatMessage({ id: 'minValidation' }, { min: 0 })),
    payFrom: yup
      .number(formatMessage({ id: 'numberValidation' }))
      .typeError(formatMessage({ id: 'numberValidation' }))
      .min(0, formatMessage({ id: 'minValidation' }, { min: 0 })),
    payTo: yup
      .number(formatMessage({ id: 'numberValidation' }))
      .typeError(formatMessage({ id: 'numberValidation' }))
      .min(0, formatMessage({ id: 'minValidation' }, { min: 0 }))
      .test(
        'is-less-or-equal',
        formatMessage({ id: 'payToValidation' }),
        function (value) {
          const { payFrom } = this.parent;
          return (
            value === undefined || payFrom === undefined || value > payFrom
          );
        }
      ),
  });

  const onSubmit = (data) => {
    setLoading(true);
    const professional = { ...data };
    professional.languages = [];
    if (professional.languages0) {
      professional.languages.push(professional.languages0);
    }
    if (professional.languages1) {
      professional.languages.push(professional.languages1);
    }
    delete professional.languages0;
    delete professional.languages1;

    dispatch(
      actionHelper.fire(PROFESSIONAL_ACTION_TYPES.ADD_REQUEST, professional)
    );
  };

  useEffect(() => {
    if (professionalAdd && professionalAdd.state === STATE.SUCCESS) {
      dispatch(actionHelper.fire(PROFESSIONAL_ACTION_TYPES.ADD_SET_STATE));
      setLoading(false);
      setSubmitted(true);
      handleOpenSnackbar(formatMessage({ id: 'submitSuccessfull' }), 'success');
    }
    if (professionalAdd && professionalAdd.state === STATE.FAILURE) {
      dispatch(actionHelper.fire(PROFESSIONAL_ACTION_TYPES.ADD_SET_STATE));
      setLoading(false);
      handleOpenSnackbar(formatMessage({ id: 'submitError' }), 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionalAdd]);

  const handleOpenSnackbar = (text, type) => {
    setSnackbar({ text, type });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(null);
  };

  const BackgroundImage = (
    <StaticImage
      src="../../../../images/group_reds_white.webp"
      alt="group_reds_1"
      placeholder="blurred"
      loading="lazy"
      layout="constrained"
      formats={['auto', 'webp', 'avif']}
    />
  );

  return (
    <article className={styles.container}>
      <Typography variant="h3" className={styles.container__title}>
        {formatMessage({ id: 'professionalFormTitle' })}
      </Typography>
      <Typography variant="h6" className={styles.container__subtitle}>
        {formatMessage({ id: 'professionalFormSubtitle' })}
      </Typography>
      <div className={styles.container__content_highlight_1}>
        {BackgroundImage}
      </div>
      <div className={styles.container__content_highlight_2}>
        {BackgroundImage}
      </div>
      <div className={styles.container__content_highlight_3}>
        {BackgroundImage}
      </div>
      <div className={styles.container__content_highlight_4}>
        {BackgroundImage}
      </div>
      <div className={styles.container__card}>
        <StepForm
          fields={fields}
          steps={steps}
          schema={schema}
          loading={loading}
          onSubmit={onSubmit}
          submitted={submitted}
          setSubmitted={setSubmitted}
          snackbar={snackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          maxWidth="lg"
        />
      </div>
    </article>
  );
};

Form.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Form);
